import { call, put, takeLatest, all, fork, select } from 'redux-saga/effects';
import {  formatCategoriesUrl, mergeArrays, parseToInteger, sortCategories } from '../../../helpers/utils';
import { adaptSeo, adaptListSeo } from '../../settings/utils';
import { modalReducers } from '../../modals/slice';
import { settingsReducers as settingsReducer, settingsReducers as settingsReducers } from '../../settings/slice';
import { APP_URL, CASHIER_URL } from '../../../config';
import { router } from '../../../routes/browserRoutes';
import { routerReducers } from '../../router/slice';
import { logger } from '../../../helpers/debugLogger';
import { PROVIDER_DISPLAY_TYPE } from '../../../components/layout/providers/types';
import { generateQueryString } from '../../../components/providers/utils';
import { RootState } from '../../store';
import { menuReducers } from '../../menu/slice';
import { AxiosApiResponse } from '../../../service/types';
import { NumberOrNull } from '../../../types/types';
import { ErrorCodes } from '../../../errors/ErrorCodes';
import { createEntities, getHeaderTotalCount, getHeaderTotalPageCount } from '../../utils';
import { ISagaActionType } from '../../types';
import { gridGamesActions } from '../games_grid/actions';
import Api from './api';
import actions from './actions';
import { buildGameParams, getPath, getSelectedIDsFromQuery, hasProtocol } from './utils';
import { gameReducers } from './slice';
import {
	ICategory,
	ICustomProvider, IGame, IGameInfoPayload, IGameParams, IGameProviders,
	IGameSearchPayload,
	IGamesGetStore, IGamesPagination, IGameStartPayload, IGameInfoResponse,
	IProvider,
	IProviderResponse,
	IProvidersParams, IGameStartResponse,
} from './types';
import { allGamesCategory } from '../../../components/layout/categories/constants';

const API = new Api();

const getStoreData = ({ Games, Settings, Profile }: RootState): IGamesGetStore => ({
	authToken           : Profile.authToken,
	providerDisplayType : Settings.initialSettings.sub_category_type || PROVIDER_DISPLAY_TYPE.PROVIDER,
	enableGameGrid      : Settings.initialSettings.enable_game_grid,
	enable_all_games    : Settings.initialSettings.enable_all_games,
	currentLanguageCode : Settings.current_language?.code,
	gameSelectedCategory: Games.GamesList.selected_category,
	selectedProviders   : Games.GamesList.selected_providers,
	pagination          : Games.GamesList.pagination,
	games               : Games.GamesList.games,
	providers           : Games.GamesList.providers,
	searchedGames       : Games.GamesList.searched_games,
	channelID           : Settings.channelID,
});


function* getCategories() {
	yield takeLatest(actions.GET_CATEGORIES, function* (action: ISagaActionType<{ withNavigate: boolean }>) {
		const { withNavigate = false } = action.data;
		const { enable_all_games, gameSelectedCategory, currentLanguageCode }: IGamesGetStore = yield select(getStoreData);
		try {
			const result: AxiosApiResponse<ICategory[]> = yield call(API.getCategoriesList);

			if (result && result.status === 200) {
				const data: ICategory[] = sortCategories(result.data.data);
				const categoriesData: ICategory[] = data;

				if (enable_all_games) {
					categoriesData.unshift(allGamesCategory);
				}

				const sitemap = formatCategoriesUrl(categoriesData, 'alias', 'name');

				const adaptedSeo = adaptListSeo(result.data.data, window.location.pathname);
				const selectedCategoryCandidate: ICategory | undefined = data.find((cat:ICategory) => cat.id === gameSelectedCategory);
				let category: NumberOrNull = null;

				if (selectedCategoryCandidate) {
					category = selectedCategoryCandidate.id;
				} else if (data && data.length > 0) {
					const [firstCategory] = data;
					category = firstCategory.id;
					yield put(gameReducers.selectCategory(category));
				}

				const foundOldCategory = data.find(cat => cat.category === gameSelectedCategory);
				if (foundOldCategory && withNavigate) {
					if (category) {
						yield put(settingsReducer.setSelectedCategory(category));
					}
					const search = window.location.search;
					const navigateTo = search ? `/${currentLanguageCode}/${foundOldCategory.alias}${search}` : `/${currentLanguageCode}/${foundOldCategory.alias}`;
					yield call(router.navigate, navigateTo);
				}

				yield put(settingsReducers.setSEOData(adaptedSeo));
				// @ts-expect-error FIXME: fix the return type of formatCategories url
				yield put(menuReducers.setSitemap(sitemap));
				yield put(gameReducers.setCategories(data));
			}
		} catch (e) {
			logger.log('e', e);
		}
	});
}

function* getCustomProviders() {
	yield takeLatest(actions.GET_CUSTOM_PROVIDERS, function* (action: ISagaActionType<{ categoryID: number }>) {
		const { data: { categoryID } } = action;
		const { selectedProviders, providers, providerDisplayType }: IGamesGetStore = yield select(getStoreData);
		const clonedProviders = providers;
		yield put(gameReducers.setUI({ customProviderLoading: true }));

		const withNavigate = selectedProviders.length > 0;

		try {
			const result: AxiosApiResponse<ICustomProvider[]> = yield call(API.getCustomProviders, { categories: categoryID });

			if (result && result.status === 200) {
				const adaptedData: ICustomProvider[] = result.data.data.map(item => ({ ...item, display_id: `c${item.id}` }));
				const allProviders = mergeArrays<ICustomProvider, IProvider>(adaptedData, clonedProviders as IProvider[]);
				yield put(gameReducers.setProviders(allProviders));
				const queryString: string = generateQueryString(selectedProviders, null, adaptedData);

				if (withNavigate) {
					yield call(router.navigate, queryString);
				}

				const customProviderIDs = getSelectedIDsFromQuery(window.location.search, adaptedData);
				yield put(gameReducers.setSelectedProviders(customProviderIDs));

				if (providerDisplayType === PROVIDER_DISPLAY_TYPE.ALL_PROVIDER) {
					yield put(actions.getProvidersAction(categoryID));
				}
			}
		} catch (e) {
			logger.log('e', e);
		}
		yield put(gameReducers.setUI({ customProviderLoading: false }));
	});
}


function* getSubProviders() {
	yield takeLatest(actions.GET_SUB_PROVIDERS, function* (action: ISagaActionType<number>) {
		const { data: category } = action;
		const { providerDisplayType }: IGamesGetStore = yield select(getStoreData);

		yield put(gameReducers.setProviders([]));

		switch (providerDisplayType) {
			case PROVIDER_DISPLAY_TYPE.PROVIDER: {
				yield put(actions.getProvidersAction(category));
				break;
			}
			case PROVIDER_DISPLAY_TYPE.CUSTOM_PROVIDER:
			case PROVIDER_DISPLAY_TYPE.ALL_PROVIDER: {
				yield put(actions.getCustomProviders(category));
				break;
			}
			default: {
				yield put(actions.getProvidersAction(category));
			}
		}
	});
}

function* getProviders() {
	yield takeLatest(actions.GET_PROVIDERS, function* (action: ISagaActionType<number>) {
		const { data: category } = action;
		const { selectedProviders, providers }: IGamesGetStore = yield select(getStoreData);
		const clonedProviders = providers;
		yield put(gameReducers.setUI({ providerLoading: true }));

		const withNavigate = selectedProviders.length > 0;

		try {
			const params: IProvidersParams = {
				categories: [category],
			};

			const result: AxiosApiResponse<IProviderResponse[]> = yield call(API.getProvidersList, params);

			if (result && result.status === 200) {
				const adaptedData: IProvider[] = result.data.data.map(item => ({ id: item.provider_id, display_id: item.provider_id.toString(), ...item }));
				const allProviders = mergeArrays<ICustomProvider, IProvider>(clonedProviders as ICustomProvider[], adaptedData);

				yield put(gameReducers.setProviders(allProviders));

				const queryString = generateQueryString(selectedProviders, null, adaptedData);

				if (withNavigate) {
					yield call(router.navigate, queryString);
				}

				const providerIDs = getSelectedIDsFromQuery(window.location.search, adaptedData);
				yield put(gameReducers.setSelectedProviders(providerIDs));
			}
		} catch (e) {
			logger.log(e);
		}

		yield put(gameReducers.setUI({ providerLoading: false }));
		// yield put(actions.uiRefresh({ providerLoading: false }));
	});
}

function* searchGames() {
	yield takeLatest(actions.SEARCH_GAMES, function* (action: ISagaActionType<IGameSearchPayload>) {
		const { searchedGames } = yield select(getStoreData);
		yield put(gameReducers.setUI({ searchLoading: true }));
		try {
			const { data } = action;
			const params: IGameParams = {
				...data,
				sort_by   : 'play_count',
				channel_id: 1,
			};
			const result: AxiosApiResponse<IGame[]> = yield call(API.getGamesList, params);

			if (result && result.status === 200) {
				const clonedGames = searchedGames ? searchedGames : [];
				yield put(gameReducers.setSearchTerm(data.name));
				yield put(gameReducers.setSearchedGames([ ...clonedGames, ...result.data.data ]));
				const x_total_count = getHeaderTotalCount(result);
				yield put(gameReducers.setSearchedGamesXTotalCount(x_total_count));
			}
		} catch (e) {
			logger.log('e', e);
		}
		yield put(gameReducers.setUI({ searchLoading: false }));

	});

}

function* getGamesList() {

	yield takeLatest(actions.GET_GAMES_LIST, function* (action: ISagaActionType<Partial<IGamesPagination>>) {
		const { data: { page, limit } } = action;
		const {
			gameSelectedCategory,
			selectedProviders,
			pagination,
			providerDisplayType,
			enableGameGrid,
			providers,
			channelID,
		}: IGamesGetStore = yield select(getStoreData);

		yield put(gameReducers.setUI({ infiniteLoading: true }));

		if (page === 1) {
			yield put(gameReducers.setUI({ gameListLoading: true }));
		}

		const paging = {
			limit: limit || pagination.limit || 108,
			page : page ?? pagination.page,
		};

		const params: IGameProviders = buildGameParams(providerDisplayType, selectedProviders, gameSelectedCategory, paging, providers, channelID);

		try {
			let result: AxiosApiResponse<IGame[]> = yield params.category && call(API.getGamesList, params);

			if (result && result.status === 200) {
				const countGames = getHeaderTotalCount(result);
				const countPage = getHeaderTotalPageCount(result);
				let pageReq = -1;

				while (!result.data.data.length && enableGameGrid && countPage && (countPage !== pageReq) && page) {
					const { pagination: { page } }: IGamesGetStore = yield select(getStoreData);
					pageReq = page;
					params.page = page;
					result = yield params.category && call(API.getGamesList, params);
					yield put(gameReducers.setPagination({ page: params.page + 1 }));
				}
				const { data } = result.data;
				const entities = createEntities(data, 'id');

				if (page === 1) {
					yield put(gameReducers.setGames(data));
				} else {
					yield put(gameReducers.attachGames(data));
				}

				yield put(gameReducers.setGamesEntities(entities));

				if (enableGameGrid) {
					yield put(gridGamesActions.defaultGridGamesReload(data));
				}
				const { pagination, games }: IGamesGetStore = yield select(getStoreData); // for get updated pagination page after calls in loop

				const finished = (enableGameGrid && countPage) ? countPage <= pagination.page : games.length === countGames;

				yield put(gameReducers.setPagination({ hasMore: !finished }));
			}
		} catch (e) {
			logger.log('e', e);
		}
		yield put(gameReducers.setUI({ infiniteLoading: false, gameListLoading: false }));
	});
}

function* infoGame() {
	yield takeLatest(actions.INFO_GAME, function* (action: ISagaActionType<IGameInfoPayload>) {
		const { authToken } = yield select(getStoreData);
		try {
			const { data } = action;
			const params = data.casino_game_id ? { casino_game_id: data.casino_game_id } : { alias: data.alias };
			const resultInfo: AxiosApiResponse<IGameInfoResponse> = yield call(API.infoGame, params);

			if (resultInfo.status === 200) {
				const { data: dataResultInfo } = resultInfo.data;
				const seo = adaptSeo(dataResultInfo);
				yield put(settingsReducers.setSEOData(seo));

				yield put(gameReducers.setGame({
					name       : dataResultInfo.name,
					description: dataResultInfo.description,
					url        : '',
					error      : '',
				}));
				if (authToken) {
					yield put(gameReducers.setGame({ alias: '' + dataResultInfo.alias || '' + data.alias, demo: false }));
					yield put(actions.startGameAction({
						alias: '' + dataResultInfo.alias || '' + data.alias,
						demo : false,
					}));
				}
			}
		} catch (e) {
			if (e instanceof Error) {
				const errorCode = parseToInteger(e.message);
				yield put(gameReducers.setGame({ url: '', error: ErrorCodes[errorCode] }));
				yield put(modalReducers.setInfoUI({ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
			}
		}
	});

}


function* startGame() {
	yield takeLatest(actions.START_GAME, function* (action: ISagaActionType<IGameStartPayload>) {
		try {
			const { data } = action;
			const params = {
				...data,
				exit_url   : APP_URL,
				cashier_url: CASHIER_URL,
				https      : true,
			};
			const result: AxiosApiResponse<IGameStartResponse> = yield call(API.startGame, params);

			if (result && result.status === 200) {
				const returnCashierURL = result.data.data.cashier_url;
				const isProtocol = hasProtocol(result.data.data.cashier_url);
				if (returnCashierURL) {
					const path = isProtocol ? getPath(returnCashierURL) : returnCashierURL;
					yield put(routerReducers.setNavigateEnd(true));
					yield put(routerReducers.setHistory({ to: path, options: { replace: true } }));
					yield put(modalReducers.setDepositUI({ visible: true }));
				}

				const { url, name, description } = result.data.data;
				yield put(gameReducers.setGame({ url, name, description, error: null }));
			}
		} catch (e) {
			if (e instanceof Error) {
				const errorCode = parseToInteger(e.message);
				yield put(gameReducers.setGame({ url: '', error: ErrorCodes[errorCode] }));

				if (errorCode === ErrorCodes.INSUFFICIENT_BALANCE) {
					yield put(modalReducers.setDepositUI({ visible: true }));
				}
			}
		}
	});
}

function* gameListSaga() {
	yield all([
		fork(searchGames),
		fork(getGamesList),
		fork(startGame),
		fork(infoGame),
		fork(getCategories),
		fork(getCustomProviders),
		fork(getProviders),
		fork(getSubProviders),
	]);
}

export default gameListSaga;
