import { PROVIDER_DISPLAY_TYPE } from '../../../components/layout/providers/types';
import { NumberOrNull } from '../../../types/types';
import { ICustomProvider, IGameProviders, IProvider } from './types';
import { CHANNELS, defaultLanguages } from '../../settings/utils';


export const buildGameParams = (
	displayType: PROVIDER_DISPLAY_TYPE,
	sub_providers: string[],
	category: NumberOrNull,
	paging: { page: number; limit: number},
	providers: (IProvider | ICustomProvider)[],
	channelID: CHANNELS
): IGameProviders => {
	const params: IGameProviders = {
		category  : category,
		sort_by   : 'play_count',
		channel_id: channelID,
		limit     : paging.limit || 20,
		page      : paging.page,
	};

	if (displayType === PROVIDER_DISPLAY_TYPE.PROVIDER) {
		params.provider_ids = sub_providers.map(e => +e);
	}

	if (displayType === PROVIDER_DISPLAY_TYPE.CUSTOM_PROVIDER) {
		const idsSub = sub_providers.map(item => item.replace(/\D/g, '')); // clear text from ids
		params.custom_provider_ids = idsSub.map(e => +e);
	}

	if (displayType === PROVIDER_DISPLAY_TYPE.ALL_PROVIDER && providers.length > 0) {
		const provider_ids: number[] = [];
		const custom_provider_ids: number[] = [];

		providers.forEach(item => {
			const targetArray = 'provider_id' in item ? provider_ids : custom_provider_ids;
			if (sub_providers.includes(item.display_id)) {
				targetArray.push(item.id);
			}
		});

		params.custom_provider_ids = custom_provider_ids;
		params.provider_ids = provider_ids;
	}

	return params;
};

export interface IProvidersArg {
	alias     : string;
	id        : number;
	display_id: string;
}
export const getSelectedIDsFromQuery = <T>(search: string, data: (T & IProvidersArg)[], key: string = 'provider'): string[] => {
	const query = new URLSearchParams(search);
	const list = query.getAll(key);
	return data.filter(item => list.includes(String(item.alias))).map(item => item.display_id);
};

export const hasProtocol = (url: string = ''): boolean => {
	return url.startsWith('http://') || url.startsWith('https://');
};

export const getPath = (url: string): string => {
	const urlObj = new URL(url);
	const pathName = urlObj.pathname;
	const language = defaultLanguages.find(lang => pathName.startsWith(`/${lang.code}/`));
	return language ? pathName.replace(`${language.code}/`, '') : pathName;

};